import { HStack, Icon, Text } from "@chakra-ui/react";
import { SiNextDotJs, SiGatsby, SiGraphql, SiJava, SiRedux, SiGithub, SiNetlify } from "react-icons/si";
import { RiReactjsFill } from "react-icons/ri";
import { FaDharmachakra, FaAws, FaDigitalOcean } from "react-icons/fa";
import * as React from 'react';
export default {
  HStack,
  Icon,
  Text,
  SiNextDotJs,
  SiGatsby,
  SiGraphql,
  SiJava,
  SiRedux,
  SiGithub,
  SiNetlify,
  RiReactjsFill,
  FaDharmachakra,
  FaAws,
  FaDigitalOcean,
  React
};