import { HStack, Icon, Text } from "@chakra-ui/react";
import { SiKubernetes, SiApollographql, SiPostgresql, SiTypescript, SiDocker, SiMysql } from "react-icons/si";
import { SiDjango, SiPython, SiJava, SiWebpack } from "react-icons/si";
import * as React from 'react';
export default {
  HStack,
  Icon,
  Text,
  SiKubernetes,
  SiApollographql,
  SiPostgresql,
  SiTypescript,
  SiDocker,
  SiMysql,
  SiDjango,
  SiPython,
  SiJava,
  SiWebpack,
  React
};