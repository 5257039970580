import { HStack, Icon, Text } from "@chakra-ui/react";
import { RiHtml5Fill, RiCss3Fill } from "react-icons/ri";
import { SiJavascript, SiSass, SiNodeDotJs, SiMongodb } from "react-icons/si";
import * as React from 'react';
export default {
  HStack,
  Icon,
  Text,
  RiHtml5Fill,
  RiCss3Fill,
  SiJavascript,
  SiSass,
  SiNodeDotJs,
  SiMongodb,
  React
};