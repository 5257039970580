import { HStack, Icon, Text } from "@chakra-ui/react";
import { SiGraphql, SiDocker } from "react-icons/si";
import { RiReactjsFill } from "react-icons/ri";
import * as React from 'react';
export default {
  HStack,
  Icon,
  Text,
  SiGraphql,
  SiDocker,
  RiReactjsFill,
  React
};